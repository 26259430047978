// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: #EEEEF4;
  color:#999;
  font-family:Roboto;
}

h1{
  font-weight:100;
  font-size:27pt;
  color:#E43;
}

p{font-weight:300;}

.warning-content {
	position:absolute;
  top:25%;
  width:100%;
  height:300px;
  text-align:center;
  margin:0;
  
  
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,UAAU;AACZ;;AAEA,EAAE,eAAe,CAAC;;AAElB;CACC,iBAAiB;EAChB,OAAO;EACP,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,QAAQ;;;AAGV","sourcesContent":["@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);\n\nbody {\n  background: #EEEEF4;\n  color:#999;\n  font-family:Roboto;\n}\n\nh1{\n  font-weight:100;\n  font-size:27pt;\n  color:#E43;\n}\n\np{font-weight:300;}\n\n.warning-content {\n\tposition:absolute;\n  top:25%;\n  width:100%;\n  height:300px;\n  text-align:center;\n  margin:0;\n  \n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
